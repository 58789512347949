import React from "react"
import { Row } from "react-bootstrap"
//import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import Slider from "react-slick";
import Img from "gatsby-image";

import Layout from "../components/layout"
import Seo from "../components/seo"
import IndexCard from "../components/indexCard"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderItem = ({ name, image, imageAlt, link }) => {
  return (
    <div>
      <Link to={link}>
        <div>
          <Img className="index-page-cat-spinner-img-image" fluid={image} alt={imageAlt} />
          <p className="pt-1 index-page-cat-spinner-text">{name.toUpperCase()}</p>
        </div>
      </Link>
    </div>
  );
};

const IndexPage = ( { data : { images } } ) => {

  var imagearr = []
  var imageFluid = []
  for (var img of images.edges) {
    imagearr[img.node.name] = img.node.childImageSharp.gatsbyImageData
    imageFluid[img.node.name] = img.node.childImageSharp.fluid
  }

 
  const CategorySlider = ({ slidesToShow }) => {
    const slider_settings = {
      arrows: true,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      //slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 5000,
    };

    return (
      <Slider {...slider_settings}>
        <SliderItem
          name="Appetizers"
          image={imageFluid["appetizer-560x560-circle"]}
          imageAlt="Cheese Tray"
          link="/recipes/view/category/appetizers/"
        />
        <SliderItem
          name="Baking"
          image={imageFluid["cake-560x560-circle"]}
          imageAlt="Cake"
          link="/recipes/view/category/baking/"
        />
        <SliderItem
          name="Beverages"
          image={imageFluid["beverage-560x560-circle"]}
          imageAlt="Drink"
          link="/recipes/view/category/beverages/"
        />
        <SliderItem
          name="Breakfast"
          image={imageFluid["pancakes-560x560-circle"]}
          imageAlt="Pancakes"
          link="/recipes/view/category/breakfast/"
        />
        <SliderItem
          name="Casseroles/ Hot&nbsp;Dish"
          image={imageFluid["casserole-560x560-circle"]}
          imageAlt="Noodle Dish"
          link="/recipes/view/category/casseroles/"
        />
        <SliderItem
          name="Cuisines"
          image={imageFluid["cuisines-560x560-circle"]}
          imageAlt="Globe with flags"
          link="/recipes/view/category/cuisines/"
        />
        <SliderItem
          name="Cooking Methods"
          image={imageFluid["cooking-method-560x560-circle"]}
          imageAlt="Dutch Oven"
          link="/recipes/view/category/cooking-methods/"
        />
        <SliderItem
          name="Chilis/ Soups/ Stews"
          image={imageFluid["soup-560x560-circle"]}
          imageAlt="Bowl of Soup"
          link="/recipes/view/category/chili-soups-stews/"
        />
        <SliderItem
          name="Desserts/ Pies"
          image={imageFluid["pie-560x560-circle"]}
          imageAlt="Pie"
          link="/recipes/view/category/desserts-pies/"
        />
        <SliderItem
          name="Fruit"
          image={imageFluid["watermelon-560x560-circle"]}
          imageAlt="Watermelon"
          link="/recipes/view/category/fruit/"
        />
        <SliderItem
          name="Kid Friendly"
          image={imageFluid["kid-friendly-560x560-circle"]}
          imageAlt="Child holding a pizza"
          link="/recipes/view/category/kid-friendly/"
        />
        <SliderItem
          name="Meat/ Fish/ Seafood"
          image={imageFluid["spare-ribs-560x560-circle"]}
          imageAlt="Ribs"
          link="/recipes/view/category/meat-fish-seafood/"
        />
        <SliderItem
          name="Occasions"
          image={imageFluid["holiday2-560x560-circle"]}
          imageAlt="Gingerbread Cookie"
          link="/recipes/view/category/occasions/"
        />
        <SliderItem
          name="Outdoor Cooking"
          image={imageFluid["outdoor2-560x560-circle"]}
          imageAlt="Large pot on fire"
          link="/recipes/view/category/outdoor-cooking/"
        />
        <SliderItem
          name="Pasta"
          image={imageFluid["pasta-560x560-circle"]}
          imageAlt="Bowls of pasta"
          link="/recipes/view/category/pasta/"
        />
        <SliderItem
          name="Quick Meals"
          image={imageFluid["stopwatch2-560x560-circle"]}
          imageAlt="Stopwatch"
          link="/recipes/view/category/quick-meals/"
        />
        <SliderItem
          name="Sandwiches"
          image={imageFluid["sandwich-560x560-circle"]}
          imageAlt="Sandwich"
          link="/recipes/view/category/sandwiches/"
        />
        <SliderItem
          name="Special Categories"
          image={imageFluid["nut-free-560x560-circle"]}
          imageAlt="Red circle with line through it"
          link="/recipes/view/category/special/"
        />
        <SliderItem
          name="Salads"
          image={imageFluid["salad-560x560-circle"]}
          imageAlt="Bowl of salad"
          link="/recipes/view/category/salads/"
        />
        <SliderItem
          name="Side Dishes"
          image={imageFluid["side-dish-560x560-circle"]}
          imageAlt="Bowl of potatoes"
          link="/recipes/view/category/side-dish/"
        />
        <SliderItem
          name="Vegetables"
          image={imageFluid["peppers-560x560-circle"]}
          imageAlt="Bell peppers"
          link="/recipes/view/category/vegetables/"
        />
        <SliderItem
          name="This & That"
          image={imageFluid["other-560x560-circle"]}
          imageAlt="Random food items"
          link="/recipes/view/category/this-and-that/"
        />
      </Slider>
    );
  };

  //<StaticImage src="../images/index-page/campfire1280x720.jpg" alt="Campfire cooking" loading="eager"/>
//<StaticImage src="../images/index-page/cookbook.jpg" alt="Cookbook" loading="eager"/>
//<StaticImage src="../images/index-page/ilovecooking1280x720.jpg" alt="Kitchen gadget graphic" loading="eager"/>
// <GatsbyImage image={imagearr["cookbook"]} alt="Kitchen gadget graphic" />
  return (
  <Layout pageInfo={{ pageName: "index" }}>
    <Seo title="Home" keywords={[`recipes`]} description="Great website with great recipes!" url="" />
    <Row
        className="justify-content-center d-flex flex-wrap pt-4 pb-3 ps-4 pe-4 text-center"
        id="index-page-browse-title"
      >
        BROWSE RECIPES BY CATEGORY
      </Row>
      <Row className="gy-4 justify-content-center d-flex flex-wrap ps-4 pe-4 text-center" id="index-page-cat-spinner">
        <div id="index-page-cat-spinner-lg" className="pb-3">
          <CategorySlider slidesToShow={8} />
        </div>
        <div id="index-page-cat-spinner-sm" className="pb-3">
          <CategorySlider slidesToShow={4} />
        </div>
        <div id="index-page-cat-spinner-xs" className="pb-3">
          <CategorySlider slidesToShow={3} />
        </div>
      </Row>

      <Row className="text-center pt-2">
        <div className="g-0 ps-2 pe-2 mb-3" id="index-page-dot-banner">
          <div className="d-flex justify-content-center">
            <div id="index-page-dot" className="d-flex align-items-center">
              <GatsbyImage image={imagearr["campfire-marshmallows-225x150"]} alt="Dutch Oven" />
            </div>
            <div className="d-flex justify-content-evenly p-2" id="index-page-dot2">
              <div className="d-flex flex-wrap flex-md-nowrap justify-content-md-around">
                <div className="d-flex align-items-center justify-content-center flex-grow-1 flex-md-shrink-1">
                  <div id="index-page-dot-title">
                    Looking for camping recipes?
                    <br />
                    Checkout our sister site!
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-grow-1 p-2">
                  <div>
                    <Link to="https://www.iloveitcamping.com">
                      <div id="index-page-dot-link">ILOVEITCAMPING.COM</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Row
        className="justify-content-center d-flex flex-wrap pt-4 pb-3 ps-4 pe-4 text-center"
        id="index-page-browse-title"
      >
        FEATURED RECIPES
      </Row>

      <Row>
        <div className="d-flex justify-content-center">
          <div
            id="index-page-recipe-container"
            className="d-flex text-center justify-content-center flex-wrap flex-shrink-1"
          >
            <IndexCard title="Dawn's Lasagna" 
              text = "This is a quick and easy recipe to make. The kids and the adults will love it. Don’t forget the garlic bread."
              image={imagearr["lasagna-2"]}
              imageAlt="Cookbook" 
              linkTo="/recipes/view/dawns-lasagna/"
            />

            <IndexCard title="Quiche Recipe" 
              text = "Simply the best French quiche recipe ever!"
              image={imagearr["quiche"]}
              imageAlt="Quiche" 
              linkTo="/recipes/view/quiche/"
            />

            <IndexCard title="Blueberry Cobbler" 
              text = "If you are on the Guntflint Trail (in Minnesota) and have picked your own blueberries, this is the recipe for you."
              image={imagearr["blueberry-cobbler"]}
              imageAlt="Blueberry Cobbler" 
              linkTo="/recipes/view/gunflint-blueberry-cobbler/"
            />

            <IndexCard title="Seasoned Hamburgers" 
              text = "This recipe turns basic hamburgers into the best hamburgers"
              image={imagearr["seasoned-hamburger"]}
              imageAlt="Seasoned Hamburger" 
              linkTo="/recipes/view/seasoned-hamburgers/"
            />

            <IndexCard title="Turkey Soup" 
              text = "This is great for making turkey soup the day after Thanksgiving. You don’t need the turkey carcass. "
              image={imagearr["turkey-pasta-soup"]}
              imageAlt="Turkey Soup" 
              linkTo="/recipes/view/turkey-pasta-soup/"
            />

            <IndexCard title="Beanless Chili Recipe" 
              text = "Easy and delicious. It turns out great every time! Don’t forget the soda crackers. "
              image={imagearr["chili3a"]}
              imageAlt="Turkey Soup" 
              linkTo="/recipes/view/beanless-chili/"
            />

            <IndexCard title="Pumpkin Cookies Recipe" 
              text = "Great peanut alternative! Don’t forget the icing because the combination of the cookie and the icing makes these absolutely scrumptious."
              image={imagearr["pumpkin_cookies"]}
              imageAlt="Turkey Soup" 
              linkTo="/recipes/view/pumpkin-cookies/"
            />

            <IndexCard title="Cranberry Bread Recipe" 
              text = "Everyone loves this holiday favorite"
              image={imagearr["cranberry-bread-800"]}
              imageAlt="Turkey Soup" 
              linkTo="/recipes/view/cranberry-bread/"
            />
          </div>
        </div>
      </Row>
  </Layout>
 )
}

//<IndexCard title="Dutch Oven Recipes" 
//        text = "We have a bunch of Dutch oven recipes. Many are super easy."
//        image={imagearr["dutch-oven-fruit-cobbler"]}
//        imageAlt="Dutch Oven Fruit Cobbler" 
//        linkTo="/recipes/view/category/dutch-oven/"
//      />

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageImages {
    images: allFile (filter: { sourceInstanceName: { eq: "index-page-images" }}) {
      edges {
        node {
          dir
          ext
          sourceInstanceName
          name
          childImageSharp {
            gatsbyImageData 
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

//      <IndexCard title="Camping Cookbook" 
//        text = "The perfect way to plan and create great meals for camping. You will be the hero at camp."
//        image={imagearr["campfire2"]}
//        imageAlt="Campfire" 
//        linkTo="/camping-cookbook/"
//      />

//        <IndexCard title="Dutch Oven Cooking Tips" 
//          text = "Wondering how to cook in a cast iron Dutch oven at a campfire? This is a fun way to cook and you can make cakes, cookies, soups, and more!"
//          image={imagearr["dutch-oven2"]}
//          imageAlt="Dutch Oven with Coals" 
//          linkTo="/dutch-oven-cooking-tips/"
//        />