import React from "react"
import { Link } from "gatsby"
import { Card } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"

const IndexCard = ({ title, text, image, imageAlt, linkTo }) => {

  return (
    <>
      <div className="indexPageCard">
        <Link to={linkTo}>
      <Card className="m-2 mb-3">
        <Card.Body className="index-card-body">
          <Card.Title className="pb-1">{title}</Card.Title>
          <GatsbyImage image={image} alt={imageAlt} />
          <div className="pt-2 index-card-text">
          <Card.Text >{text}</Card.Text>
          </div>
        </Card.Body>
      </Card>
      </Link>
      </div>
    </>
  )
}

export default IndexCard
